/************************************************
 * Created By nanyuantingfeng On 5/30/16 02:44.
 ************************************************/
import { getUrlParamString } from '@ekuaibao/lib/lib/lib-util'
import { app as api } from '@ekuaibao/whispered'
import { resignationUrl } from '@ekuaibao/lib/lib/enums'
import { get } from 'lodash'
import { showModal, showMessage } from '@ekuaibao/show-util'
import loadable from '@loadable/component'

export default [
  {
    id: '@layout',

    'change:badge'(menuId, count) {
      api.store.dispatch('@layout/changeBadge')(menuId, count)
    },

    'delete:menus'(menus) {
      api.store.dispatch('@layout/deleteMenus')(menus)
    },

    'add:menus'(menus) {
      api.store.dispatch('@layout/addMenus')(menus)
    },

    'sync:menu:4:route:changed'() {
      api.store.dispatch('@layout/SyncMenu4RouteChange')()
    },

    'event:message:go': async (message) => {
      let path = message.url

      if (message.sourceType === 'pwdExpireRemind') {
        return api.go(`/user-info/safeSetting?safeSetType=password`, true)
      }

      if (!path) return
      if (
        path.toLowerCase().startsWith('http') &&
        message.templateId !== 'asyncExport:success' &&
        message.templateId !== 'asyncPrint:success'
      ) {
        return api.emit('@vendor:open:link', path)
      }
      const params = (function () {
        const arr = path?.replace('/blockUIMessage?', '')?.split('&')
        const obj = {}
        for (var i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=')
          obj[arr2[0]] = decodeURIComponent(arr2[1])
        }
        return obj
      })()
      // debugger;
      if (path.includes('/blockUIMessage?')) {
        return api.open('@layout:BlockUiModal', {
          id: params?.sourceId,
          type: params?.type,
        })
      }
      // 订单确认消息跳转
      if (message.templateId === 'orderConfirm') {
        return api.go('/order-confirm')
      }
      if (message.templateId === 'staff:apply') {
        return api.go('/contacts')
      }
      // 导出任务消息 点击直接下载
      if (message.templateId === 'asyncExport:success') {
        const link = document.createElement('a')
        link.href = decodeURI(message.url)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return
      }
      // 打印任务消息 点击跳转打印页面
      if (message.templateId === 'asyncPrint:success') {
        const { url, content } = message
        const name = content.match(/「(\S*)」/)[1].concat('.pdf')
        // 后台返回的url已经编码过了，但是？&没有编码， doc.ekuaibao.com第三方的预览的url参数是要全部编码的
        const link = `https://doc.ekuaibao.com/view/url?url=${encodeURIComponent(
          decodeURI(url),
        )}&name=${name}`
        const watermark = api.getState()['@common'].waterMark
        watermark && watermark !== ''
          ? (link = link + '&watermark=' + encodeURIComponent(watermark))
          : ''
        return api.emit('@vendor:open:link', link)
      }
      let { sourceType, sourceStatus, messageType, title, url, templateId, id } = message

      // 合适工作台-已购买
      if (url.indexOf('appcenter-purchased_apps') > -1) {
        return api.go('/expense-center-new/purchased_apps/APPMANAGE/0')
      }

      // 合适工作台-已过期
      if (url.indexOf('appcenter-expired_apps') > -1) {
        return api.go('/expense-center-new/purchased_apps/APPMANAGE/2')
      }

      // 合适工作台-订单管理
      if (url.indexOf('appcenter-orders') > -1) {
        return api.go('/expense-center-new/purchased_apps/ORDERMANAGE')
      }

      // 合适工作台-驾驶舱
      if (url.indexOf('appcenter-purchased_home') > -1) {
        return api.go('/expense-center-new/purchased_apps/COCKPIT')
      }

      if (url.indexOf('appcenter-homepage') > -1) {
        return api.go(`/expense-center-new/home`)
      }
      if (sourceType === 'assistPlatform') {
        return api.go(`/assistance`)
      } else if (sourceType === 'applyEventDetail') {
        api.invokeService('@bills:get:apply:event:by:id', message.sourceId).then((res) => {
          // console.log(res, 'res')
          api.open('@bills:ApplyEventStackerModal', {
            viewKey: 'ApplyEventDetail',
            showButton: true,
            detail: res?.value,
            flowId: res?.value?.flowId,
          })
        })
        return
      } else if (sourceType == 'repayment') {
        //还款
        let privilegeId = getUrlParamString(url, 'privilegeId')
        if (sourceStatus == 'APPROVE') {
          //审批通知
          if (privilegeId) {
            api
              .invokeService('@loan-manage:check:privilege', {
                start: 0,
                count: 0,
                privilegeId,
              })
              .then((_) => {
                api.go(`/loan-manage-privilege/${privilegeId}`)
                setTimeout(() => {
                  api.open('@loan-manage:LoanRepaymentModal', { privilegeId })
                }, 0)
              })
          } else {
            let userinfo = api.getState()['@common'].userinfo
            let permissions = get(userinfo, 'data.permissions', [])
            if (!permissions.includes('LOAN_MANAGE')) {
              return showMessage.error(i18n.get('您无权查看该还款申请！'))
            }
            api.go('/loan-manage')
            setTimeout(() => {
              api.open('@loan-manage:LoanRepaymentModal')
            }, 0)
          }
        } else if (['REJECT', 'AGREE'].includes(sourceStatus)) {
          //还款申请驳回||还款申请确认
          api
            .invokeService('@bills:get:loanpackage:detail:RepayInfo', {
              loanInfoId: params.loanInfoId,
            })
            .then((res) => {
              api
                .invokeService('@bills:get:loanpackage:detail:info', { id: params.loanInfoId })
                .then((action) => {
                  api.open('@bills:BillStackerModal', {
                    viewKey: 'LoanPackageDetailView',
                    loanDetail: action.value,
                    repayInfo: res.items[0],
                    showRepaymentButton: true,
                  })
                })
            })
        }
      } else if (
        sourceType == 'freeflow' ||
        (messageType == 'REMIND' && sourceType !== 'invoiceRemind' && sourceType !== 'loanInfo')
      ) {
        //单据通知
        // if (sourceStatus == 'reject') {
        //   //驳回
        //   api.invokeService('@bills:get:flow-info', { id: message.sourceId }).then(resp => {
        //     let { value: dataSource } = resp
        //     api.open('@bills:BillInfoModifyModal', {
        //       dataSource
        //     })
        //   })
        // } else
        if (title === i18n.get('有单据需要你支付') || title.indexOf('单据需要你的支付') > -1) {
          //审批通过,待支付
          api.go('/audit/payment')
        } else if (sourceStatus === 'PRINT') {
          if (window.isNewHome) {
            api.go('/todo/print')
          } else {
            api.open('@bills:ArchivedStackerModal', {
              viewKey: 'MessageListView',
              type: 'printList',
              copyAble: false,
            })
          }
        } else if (['agree', 'reject'].includes(sourceStatus)) {
          //打印提醒，单据已支付
          api.invokeService('@bills:get:flow-info', { id: message.sourceId }).then((resp) => {
            let { value: flowId } = resp
            let { actions } = flowId
            let buttons = actions
            api.open('@bills:BillStackerModal', {
              viewKey: 'NewBillInfoView',
              buttons,
              backlog: { id: -1, flowId },
              invokeService: '@bills:get:flow-info',
              params: { id: flowId.id, autoClose: sourceStatus == 'reject' },
            })
          })
        } else if (messageType === 'REMIND' || templateId === 'carbonCopy') {
          api.invokeService('@bills:get:flow-info', { id: message.sourceId }).then((resp) => {
            const { value: flowId } = resp
            api.open('@bills:BillStackerModal', {
              viewKey: 'BillInfoView',
              dataSource: flowId,
            })
          })
        } else {
          api.go('/audit/approve')
        }
      } else if (sourceType === 'requisition_info_list') {
        //申请共享和转交
        api.open('@bills:ApplyEventStackerModal', {
          viewKey: 'ApplyEventListView',
          fetchData: function (a) {
            // return getApplyEvent(a).payload
            return api.invokeService('@bills:get:apply:event:fn', a).payload
          },
          style: {
            backgroundColor: '#fbfbfb',
          },
        })
      } else if (sourceType === 'loanInfo') {
        try {
          // 如果是共享借款消息打开前先确认是否被取消共享
          if (templateId === 'share:start') {
            const result = await api.invokeService('@bills:get:loan:isCancel:share', {
              id: params.loanInfoId,
            })
            if (result?.value?.isCancel) {
              api.go('/myLoan')
              return showModal.info({
                title: i18n.get('详情'),
                content: `${result?.value?.canceller}${i18n.get('已取消共享此借款包')}`,
                okText: i18n.get('确定'),
              })
            }
          }
          const [res, action] = await Promise.all([
            api.invokeService('@bills:get:loanpackage:detail:RepayInfo', {
              loanInfoId: params.loanInfoId,
            }),
            api.invokeService('@bills:get:loanpackage:detail:info', {
              id: params.loanInfoId,
            }),
          ])
          api.open('@bills:BillStackerModal', {
            viewKey: 'LoanPackageDetailView',
            loanDetail: action.value,
            repayInfo: res.items[0],
            showRepaymentButton: true,
          })
        } catch (e) {
          showMessage.error(e.msg ?? e.message)
        }
      } else if (sourceType === 'invoiceRemind') {
        //打印提醒，单据已支付
        api.invokeService('@bills:get:flow-info', { id: message.sourceId }).then((resp) => {
          let { value: flowId } = resp
          api.open('@bills:BillStackerModal', {
            viewKey: 'BillInfoView',
            dataSource: resp.value,
            source: 'MessageListView',
            invokeService: '@bills:get:flow-info',
            params: { id: flowId.id },
          })
        })
      } else if (params.source === 'dimission') {
        const href = `${location.origin}/applet/${resignationUrl.APP}`
        return api.emit('@vendor:open:link', href)
      } else if (params.source === 'nonCompleteRoute') {
        return showMessage.info(i18n.get('请前往「移动端」查看私车公用未完行程'))
      } else if (params.source === 'budgetalert') {
        api.go('/reports/budget')
      } else if (params.source === 'mcinvite') {
        api.open('@layout:InvitationLinkModal', params)
      } else {
        api.go('/audit/approve')
      }
    },
    'active:menu'() {
      api.store.dispatch('@layout/activeMenu')()
    },
  },

  {
    path: '/404',
    onload: () => import('./404'),
  },

  {
    point: '@@layers',
    prefix: '@layout',
    onload: () => import('./layers'),
  },

  {
    point: '@@layers',
    prefix: '@layout',
    onload: () => import('./stackers'),
  },

  {
    resource: '@layout',
    value: {
      ['message/message-view']: loadable(() => import('./message/message-view')),
      ['stackers/datagrid-condition-view']: loadable(() =>
        import('./stackers/datagrid-condition-view'),
      ),
    },
  },
]
